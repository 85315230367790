import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

import AccessibilityIcon from '@static/img/icons/icon_accessibility.inline.svg'
import ArrowCircled from '@static/img/icons/icon_arrow_circled.inline.svg'
import ArrowDown from '@static/img/icons/icon_arrow_down.inline.svg'
import ArrowExternal from '@static/img/icons/icon_arrow_external.inline.svg'
import ArrowLeft from '@static/img/icons/icon_arrow_left.inline.svg'
import ArrowRight from '@static/img/icons/icon_arrow_right.inline.svg'
import ArrowUp from '@static/img/icons/icon_arrow_up.inline.svg'
import BackToTop from '@static/img/icons/icon_backtotop.inline.svg'
import CarIcon from '@static/img/icons/icon_car.inline.svg'
import ChevronDown from '@static/img/icons/icon_chevron_down.inline.svg'
import ChevronRight from '@static/img/icons/icon_chevron_right.inline.svg'
import ChevronRightBig from '@static/img/icons/icon_chevron_big_right.inline.svg'
import ChevronRightSlim from '@static/img/icons/icon_chevron_right_slim.inline.svg'
import ChevronUp from '@static/img/icons/icon_chevron_up.inline.svg'
import Close from '@static/img/icons/icon_close.inline.svg'
import CountdownLive from '@static/img/icons/icon_live.inline.svg'
import Download from '@static/img/icons/icon_download.inline.svg'
import EasyLang from '@static/img/icons/icon_easylang.inline.svg'
import Home from '@static/img/icons/icon_home.inline.svg'
import IndoorNavigationIcon from '@static/img/icons/icon_indoor_navigation.inline.svg'
import Info from '@static/img/icons/icon_info.inline.svg'
import Pause from '@static/img/icons/icon_pause.inline.svg'
import Play from '@static/img/icons/icon_play.inline.svg'
import Plus from '@static/img/icons/icon_plus.inline.svg'
import Quote from '@static/img/icons/icon_quote.inline.svg'
import ShipIcon from '@static/img/icons/icon_ship.inline.svg'
import SourcecodeIcon from '@static/img/icons/icon_sourcecode.inline.svg'
import SpeechBubble from '@static/img/icons/icon_speechbubble.inline.svg'
import TrainIcon from '@static/img/icons/icon_train.inline.svg'
import UrbanIcon from '@static/img/icons/icon_urban.inline.svg'
import WeatherIcon from '@static/img/icons/icon_weather.inline.svg'

import NahverkehrIcon from '@static/img/icons/icon_nahverkehr.svg'
import InfrastrukturIcon from '@static/img/icons/icon_verkehrsinfrastruktur.svg'
import MeerIcon from '@static/img/icons/icon_meer.svg'
import FliessenderVerkehrIcon from '@static/img/icons/icon_fliessender_verkehr.svg'
import GesellschaftIcon from '@static/img/icons/icon_gesellschaft.svg'

const IconSet = {
  AccessibilityIcon,
  ArrowCircled,
  ArrowDown,
  ArrowExternal,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BackToTop,
  CarIcon,
  ChevronDown,
  ChevronRight,
  ChevronRightBig,
  ChevronRightSlim,
  ChevronUp,
  Close,
  CountdownLive,
  Download,
  EasyLang,
  Home,
  IndoorNavigationIcon,
  Info,
  Pause,
  Play,
  Plus,
  Quote,
  ShipIcon,
  SourcecodeIcon,
  SpeechBubble,
  TrainIcon,
  UrbanIcon,
  WeatherIcon,
  NahverkehrIcon,
  InfrastrukturIcon,
  MeerIcon,
  FliessenderVerkehrIcon,
  GesellschaftIcon,
}

export type IconProps = BASE.Helpers.IReactDefaultProps & {
  name: keyof typeof IconSet
  fontSize?: 'inherit' | 'small' | 'large' | 'medium'
  color?: 'primary' | 'inverted' | 'tertiary' | string
  viewBox?: string
}

export default function Icon({
  name,
  fontSize,
  color,
  viewBox,
  sx,
}: IconProps) {
  const Svg = IconSet[name]

  if (!Svg) return null

  return (
    <SvgIcon
      sx={[
        (theme) => ({
          '& path': {
            transition: '0.2s',
            fill: color
              ? theme.palette.text[color]
              : theme.palette.text.primary,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      fontSize={fontSize}
      component={Svg as React.ElementType<unknown>}
      viewBox={viewBox}
      inheritViewBox={!!!viewBox}
    />
  )
}
