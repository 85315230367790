import React from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { navigate } from 'gatsby'

/**
 * Adds a language switcher component.
 * Since there are currently only 2 languages, the language to switch to is displayed, instead of e.g. a select box or similar.
 * @returns {React.FC}
 */
const LanguageSwitcher: React.FC = () => {
  const { lang, setLanguage } = useLanguageContext()

  const handleChangeLanguage = () => {
    const newLang = lang === 'de' ? 'en' : 'de'
    setLanguage(newLang)

    const currentPath = window.location.pathname
    const newPath =
      newLang === 'de'
        ? currentPath.replace('/en/', '/')
        : currentPath.replace(/^\/(?!en\/)/, '/en/')

    navigate(newPath)
  }

  const languageToSwitchTo = lang === 'de' ? 'English' : 'Deutsch'

  return <button onClick={handleChangeLanguage}>{languageToSwitchTo}</button>
}

export default LanguageSwitcher
