import React, { useContext, useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { defaultLang, messages } from '@system/translations/index'

type LanguageContextType = {
  lang: string
  setLanguage: (lang: string) => void
}

const LanguageContext = React.createContext<LanguageContextType>({
  lang: defaultLang,
  setLanguage: Function.prototype as (lang: string) => void,
})

const LanguageProvider: React.FC<{ initialLang?: string }> = ({
  children,
  initialLang,
}) => {
  const [lang, setLanguage] = React.useState(initialLang || defaultLang)

  const contextValue = useMemo(
    () => ({ lang, setLanguage }),
    [lang, setLanguage]
  )

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider locale={lang} messages={messages[lang]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export function useLanguageContext() {
  return useContext(LanguageContext)
}

export default LanguageProvider
