import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Container, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

interface Day {
  name: string
  events: {
    time: string
    name: string
  }
}

export default function Jury() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)
  const days: Day[] = translated.pages.ablauf.days

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        flex: 1,
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="lg" id="headline">
        <Typography variant="h1" gutterBottom>
          {translated.pages.ablauf.intro.headline}
        </Typography>
      </Container>

      {translated.pages.ablauf.intro.copy.length > 0 && (
        <Container component="section" maxWidth="lg" sx={{ mb: 9 }}>
          <Typography variant="body2">
            {ReactHtmlParser(translated.pages.ablauf.intro.copy)}
          </Typography>
        </Container>
      )}

      <Container
        component="section"
        maxWidth="lg"
        sx={(theme) => ({
          marginTop: theme.spacing(11),
        })}
      >
        {Object.entries(days).map(([key, day]) => {
          return (
            <Container
              component="div"
              key={key}
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 12, 0),
                [theme.breakpoints.up('lg')]: {
                  margin: theme.spacing(0, 0, 16, 0),
                },
              })}
            >
              <Container
                component="div"
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.yellow[500],
                  borderRadius: theme.spacing(11),
                  width: theme.spacing(22),
                  height: theme.spacing(22),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0 auto 32px auto',
                  [theme.breakpoints.up('md')]: {
                    margin: theme.spacing(0, 0, 8, 0),
                  },
                })}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '700', whiteSpace: 'nowrap' }}
                >
                  {day.name}
                </Typography>
              </Container>

              <TableContainer component="div">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={(theme) => ({
                          width: '100px',
                          [theme.breakpoints.up('md')]: {
                            width: '160px',
                          },
                          [theme.breakpoints.up('lg')]: {
                            width: '215px',
                          },
                        })}
                      >
                        {translated.pages.ablauf.general.time}
                      </TableCell>
                      <TableCell component="th">
                        {translated.pages.ablauf.general.event}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(day.events).map(([key, event]) => {
                      return (
                        <TableRow key={key}>
                          <TableCell
                            dangerouslySetInnerHTML={{ __html: event.time }}
                          />
                          <TableCell
                            dangerouslySetInnerHTML={{ __html: event.name }}
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          )
        })}
      </Container>
    </Container>
  )
}
