import React from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Container, Typography } from '@mui/material'

export default function Impressum() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        flex: 1,
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="md" id="headline">
        <Typography
          variant="h1"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: translated.pages.impressum.headline,
          }}
        />
      </Container>
      <Container component="section" maxWidth="md">
        <Typography variant="body2" sx={{ mb: 8 }}>
          <strong>{translated.pages.impressum.contactData.name}</strong>
          <br />
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: translated.pages.impressum.contactData.email,
            }}
          />
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: translated.pages.impressum.contactData.phone,
            }}
          />
          <br />
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: translated.pages.impressum.contactData.responsible,
            }}
          />
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: translated.pages.impressum.contactData.address,
            }}
          />
        </Typography>
      </Container>
      <Container component="section" maxWidth="md">
        <Typography
          variant="h3"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: translated.pages.impressum.realization.heading,
          }}
        />
      </Container>
      <Container component="section" maxWidth="md">
        <Typography
          variant="body2"
          sx={{ mb: 8 }}
          dangerouslySetInnerHTML={{
            __html: translated.pages.impressum.realization.copy,
          }}
        />
      </Container>
      <Container component="section" maxWidth="md">
        <Typography
          variant="h3"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: translated.pages.impressum.legalNotice.heading,
          }}
        />
      </Container>
      <Container component="section" maxWidth="md">
        <Typography
          variant="body2"
          sx={{ mb: 8 }}
          dangerouslySetInnerHTML={{
            __html: translated.pages.impressum.legalNotice.copy,
          }}
        />
      </Container>
      <Container component="section" maxWidth="md">
        <Typography
          variant="h3"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: translated.pages.impressum.copyright.heading,
          }}
        />
      </Container>
      <Container component="section" maxWidth="md">
        <Typography
          variant="body2"
          sx={{ mb: 8 }}
          dangerouslySetInnerHTML={{
            __html: translated.pages.impressum.copyright.copy,
          }}
        />
      </Container>
    </Container>
  )
}
