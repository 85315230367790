exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ablauf-tsx": () => import("./../../../src/pages/ablauf.tsx" /* webpackChunkName: "component---src-pages-ablauf-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-daten-tsx": () => import("./../../../src/pages/daten.tsx" /* webpackChunkName: "component---src-pages-daten-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jury-tsx": () => import("./../../../src/pages/jury.tsx" /* webpackChunkName: "component---src-pages-jury-tsx" */),
  "component---src-pages-optin-tsx": () => import("./../../../src/pages/optin.tsx" /* webpackChunkName: "component---src-pages-optin-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */)
}

