import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

interface ImageTextProps {
  headline: string
  text: string
  image: {
    url: string
    alt: string
  }
}

export default function ImageText({
  headline,
  text,
  image,
}: ImageTextProps): ReactElement {
  return (
    <Grid
      container
      xs={12}
      spacing={6}
      sx={(theme) => ({
        marginBottom: theme.spacing(8),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(14),
        },
      })}
    >
      <Grid
        xs={12}
        md={4}
        sx={(theme) => ({
          '& img': {
            width: '100%',

            [theme.breakpoints.down('md')]: {
              maxWidth: '200px',
            },
          },
        })}
      >
        <img src={image.url} alt={image.alt} />
      </Grid>
      <Grid xs={12} md={8}>
        {headline && (
          <Typography
            variant="body2"
            component="h4"
            sx={(theme) => ({
              fontWeight: '700',
              marginBottom: theme.spacing(6),
            })}
          >
            {headline}
          </Typography>
        )}
        <Typography
          variant="body2"
          component="p"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Grid>
    </Grid>
  )
}
