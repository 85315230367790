import React, { ReactElement } from 'react'
import ReactHtmlParser from 'react-html-parser'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material'
import Button from '@components/core/button'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import PlusIcon from '@static/img/icons/icon_plus_circle.inline.svg'
import Icon from '@components/core/icon'

interface IconAccordionElement {
  icon: string
  headline1: string
  headline2: string
  copy: string
  dataFormat: string
  button: {
    url: string
    label: string
  }
  documentation: {
    url: string
    label: string
  }
  meta: {
    label: string
    url: string
  }
}

interface IconAccordionProps {
  data: IconAccordionElement[]
}

export default function IconAccordion({
  data,
}: IconAccordionProps): ReactElement {
  return (
    <Grid
      container
      xs={12}
      spacing={6}
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          width: 'calc(100% + 24px)',
        },
      })}
    >
      {Object.entries(data).map(([key, value]) => {
        return (
          <Grid xs={12} key={key}>
            <Accordion
              elevation={0}
              sx={(theme) => ({
                padding: '0 !important',
                marginBottom: '0 !important',
                color: theme.palette.common.black,
                border: 'none',
                backgroundColor: theme.palette.common.grey[100],
              })}
            >
              <AccordionSummary
                expandIcon={<PlusIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={(theme) => ({
                  height: 'auto',
                  padding: theme.spacing(2, 4, 2, 1) + ' !important',
                  borderRadius: '12px',
                  [theme.breakpoints.up('md')]: {
                    padding: theme.spacing(6) + ' !important',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    color: theme.palette.common.black,

                    [theme.breakpoints.down('md')]: {
                      width: '30px',
                      height: '30px',

                      '& svg': {
                        width: '30px',
                        height: '30px',
                      },
                    },
                  },
                })}
              >
                <Grid container direction={{ xs: 'column', md: 'row' }}>
                  <Grid item auto>
                    <Box
                      sx={(theme) => ({
                        background: theme.palette.common.yellow[500],
                        borderRadius: theme.spacing(11),
                        fontSize: theme.spacing(7),
                        width: theme.spacing(12),
                        aspectRatio: '1/1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                        [theme.breakpoints.up('md')]: {
                          fontSize: theme.spacing(12),
                          width: theme.spacing(22),
                        },
                      })}
                    >
                      <Icon name={value.icon} fontSize="inherit" />
                    </Box>
                  </Grid>
                  <Grid
                    xs
                    sx={(theme) => ({
                      display: value.headline2 ? 'block' : 'flex',
                      alignItems: 'center',
                      [theme.breakpoints.down('md')]: {
                        paddingTop: 0,
                      },
                    })}
                  >
                    <Typography
                      variant="h2"
                      sx={(theme) => ({
                        marginBottom: '8px !important',
                        [theme.breakpoints.up('md')]: {
                          marginBottom: '16px !important',
                        },
                      })}
                    >
                      {value.headline1}
                    </Typography>
                    {value.headline2 && (
                      <Typography component="span" variant="h5">
                        {ReactHtmlParser(value.headline2)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                sx={(theme) => ({
                  padding: '0 16px 32px !important',

                  [theme.breakpoints.up('md')]: {
                    padding: '0 48px 68px !important',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    padding: '0',
                    [theme.breakpoints.up('md')]: {
                      padding: '0 82px 0 100px',
                    },
                  })}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: value.copy }}
                    sx={(theme) => ({
                      '& h4': {
                        fontWeight: 'bold',
                      },
                      '& p:not(:last-child)': {
                        marginBottom: theme.spacing(6),
                      },
                    })}
                  ></Typography>
                  {value.dataFormat && (
                    <Typography variant="body2" component="div" sx={{ mt: 4 }}>
                      Format: {value.dataFormat}
                    </Typography>
                  )}
                  <Grid container gap={1} sx={{ px: 0 }}>
                    {value.documentation.url && (
                      <Grid>
                        <Link
                          href={value.documentation.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {value.documentation.label}
                        </Link>
                      </Grid>
                    )}
                    {value.meta.url && (
                      <Grid>
                        <Link
                          href={value.meta.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {value.meta.label}
                        </Link>
                      </Grid>
                    )}
                    {value.button?.url && value.button?.label && (
                      <Grid xs={12}>
                        <Button
                          to={value.button.url}
                          variant="primary"
                          isExternal={true}
                          sx={(theme) => ({
                            width: '100%',
                            [theme.breakpoints.up('md')]: {
                              width: 'auto',
                            },
                          })}
                        >
                          {value.button.label}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      })}
    </Grid>
  )
}
