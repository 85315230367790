import { Components, Theme } from '@mui/material'

export const TableOverrides = (theme: Theme): Components['MuiTable'] => ({
  styleOverrides: {
    root: {
      minWidth: '320px',
      '& th': {
        fontWeight: '700',
      },
      '& td, & th': {
        border: 0,
        padding: theme.spacing(0, 0, 2, 0),
        verticalAlign: 'top',
      },
    },
  },
})
