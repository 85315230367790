import * as React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import Box from '@mui/material/Box'
//import Button from '@components/core/button'

interface HeroProps {
  patternBottom: string
  image: string
  imageAltText: string
  preHeadline: string
  headline: string
  subHeadline: string
  buttonText: string
  buttonLink: string
  bullets: string[]
}

export default function Hero(props: HeroProps): React.ReactElement {
  return (
    <Paper
      sx={(theme) => ({
        height: 'auto',
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${props.image})`,
        [theme.breakpoints.up('lg')]: {
          minHeight: '590px',
        },
      })}
    >
      {/* Increase the priority of the hero background image */}
      {
        <img
          style={{ display: 'none' }}
          src={props.image}
          alt={props.imageAltText}
        />
      }
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,83,142,.5)',
        }}
      />
      <Box
        sx={(theme) => ({
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '450px',
          height: '125px',
          backgroundImage: `url(${props.patternBottom})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top left',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        })}
      />
      <Grid container>
        <Grid
          xs={12}
          md={10}
          mdOffset={1}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },
          })}
        >
          <Box
            sx={(theme) => ({
              position: 'relative',
              padding: theme.spacing(42, 0, 7, 0),
              [theme.breakpoints.up('md')]: {
                padding: theme.spacing(40, 0),
              },
              [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(30, 0),
              },
            })}
          >
            <Typography variant="h5" gutterBottom sx={{ fontSize: '24px' }}>
              {props.preHeadline}
            </Typography>
            <Typography
              component="h1"
              color="inherit"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: '68px',
                lineHeight: '73px !important',
              }}
            >
              {props.headline}
            </Typography>
            <Typography
              variant="h5"
              color="inherit"
              paragraph
              sx={(theme) => ({
                fontSize: '30px',
                fontWeight: '400',
                lineHeight: '43px',
                marginBottom: theme.spacing(8),
              })}
            >
              {props.subHeadline}
            </Typography>

            {props.bullets.length > 0 && (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexFlow: 'column wrap',
                  marginBottom: theme.spacing(10),

                  [theme.breakpoints.up('lg')]: {
                    flexFlow: 'row wrap',
                    marginBottom: theme.spacing(8),
                  },
                })}
              >
                {props.bullets.map((bullet, index) => {
                  return (
                    <Box
                      key={index}
                      sx={(theme) => ({
                        display: 'flex',
                        flexFlow: 'row nowrap',
                        alignItems: 'center',

                        '&:not(:last-of-type)': {
                          margin: theme.spacing(0, 0, 3, 0),
                          [theme.breakpoints.up('lg')]: {
                            margin: theme.spacing(0, 10, 0, 0),
                          },
                        },
                      })}
                    >
                      <svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="17" cy="17" r="17" fill="#FFCC00" />
                        <path
                          d="M24.2865 11.6573L14.9365 21.3716L10.6865 16.9561"
                          stroke="#00538E"
                          strokeWidth="2.23377"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <Typography
                        variant="body2"
                        sx={(theme) => ({ marginLeft: theme.spacing(2) })}
                      >
                        {bullet}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
            )}
            {/* 
            <Button
              to={props.buttonLink}
              variant="secondary"
              sx={(theme) => ({
                width: '100%',
                [theme.breakpoints.up('md')]: {
                  width: 'auto',
                },
              })}
            >
              {props.buttonText}
            </Button> */}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
