import { Components, Theme } from '@mui/material'

export const TypographyOverrides = (
  theme: Theme
): Components['MuiTypography'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      '> *': {
        marginTop: 0,
        marginBottom: 0,
      },
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
})
