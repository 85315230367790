import React from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'

import { Link as MuiLink } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import IconObject, { IconProps } from '../icon'

export type LinkProps = BASE.Helpers.IReactDefaultProps & {
  id?: string
  to?: string
  download?: boolean
  icon?: IconProps
  iconLeft?: boolean
  nofill: boolean // Requird for SVGs, which aren't allowed to be filled with the link color
  isExternal?: boolean
  target?: string
  linkColor?: 'main' | 'black' | 'inverted'
  className?: string //comes maybe from other mui inherits
  onClick?: (event: React.SyntheticEvent) => void
}

export default function Link({
  id,
  children,
  className,
  to,
  download,
  isExternal = false,
  onClick,
  icon,
  iconLeft,
  nofill = false,
  linkColor = 'main',
  target = '_blank',
  sx = null,
}: LinkProps) {
  const { lang } = useLanguageContext()
  const localizedPath = lang === 'de' ? to : `/${lang}${to}`

  const LinkComponent =
    !to && !onClick
      ? 'div'
      : !to && onClick
      ? 'button'
      : isExternal || download
      ? 'a'
      : GatsbyLink

  // Localize the paths of internal links
  if (to && !isExternal && !download) {
    to = localizedPath
  }

  const additionalProps =
    !to && onClick
      ? {}
      : isExternal
      ? { href: to, target: target, rel: 'noreferrer' }
      : download
      ? { href: to }
      : { to }

  return (
    <MuiLink
      id={id}
      className={className}
      component={LinkComponent}
      underline="none"
      onClick={onClick}
      download={download}
      {...additionalProps}
      sx={[
        (theme) => ({
          transition: '0.2s',
          color:
            linkColor === 'inverted'
              ? theme.palette.text.inverted
              : linkColor === 'black'
              ? theme.palette.text.primary
              : theme.palette.common.red[500],
          '& .MuiSvgIcon-root': {
            transition: '0.2s',
            color:
              linkColor === 'inverted'
                ? theme.palette.text.inverted
                : linkColor === 'black'
                ? theme.palette.text.primary
                : theme.palette.common.red[500],
            '& path': !nofill && {
              fill:
                linkColor === 'inverted'
                  ? theme.palette.text.inverted
                  : linkColor === 'black'
                  ? theme.palette.text.primary
                  : theme.palette.common.red[500],
            },
          },
          '&:hover': {
            color:
              linkColor === 'inverted'
                ? theme.palette.common.grey[300]
                : linkColor === 'black'
                ? theme.palette.text.tertiary
                : theme.palette.common.darkRed[500],
            '& .MuiSvgIcon-root': {
              color:
                linkColor === 'inverted'
                  ? theme.palette.common.grey[300]
                  : linkColor === 'black'
                  ? theme.palette.text.tertiary
                  : theme.palette.common.darkRed[500],
              '& path': !nofill && {
                fill:
                  linkColor === 'inverted'
                    ? theme.palette.common.grey[300]
                    : linkColor === 'black'
                    ? theme.palette.text.tertiary
                    : theme.palette.common.darkRed[500],
              },
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {!!icon && iconLeft && (
        <IconObject
          {...icon}
          sx={[
            (theme) => ({
              marginTop: '-2px',
              marginRight: theme.spacing(4),
            }),
            ...(Array.isArray(icon.sx) ? icon.sx : [icon.sx]),
          ]}
        />
      )}
      {children}
      {!!icon && !iconLeft && (
        <IconObject
          {...icon}
          sx={[
            (theme) => ({
              marginTop: '-2px',
              marginLeft: theme.spacing(4),
            }),
            ...(Array.isArray(icon.sx) ? icon.sx : [icon.sx]),
          ]}
        />
      )}
    </MuiLink>
  )
}
