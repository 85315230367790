import React, { ReactElement, useState } from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import HomeLogo from '@static/img/logos/homelogo.svg'

import { AppBar, Toolbar, Grid, Box, SvgIcon } from '@mui/material'

import HorizontalNav from '@components/modules/global/navigation/horizontalNav'

import { INavItemData } from '@components/modules/global/navigation/drawerNav'
//import Button from '@components/core/button'
import LanguageSwitcher from '@components/core/languageSwitcher'
import Burger from '@components/core/burger'
import mfundLogo from '@static/img/logos/logo-mfund.svg'
import Link from '@components/core/link'

export type HeaderProps = {
  // TODO: make navigationItems required again
  navigationItems?: Array<INavItemData>
  scrollDir: string
}

export default function Header({
  navigationItems,
  scrollDir,
}: HeaderProps): ReactElement {
  const [navOpen, setNavOpen] = useState(false)
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  const mobileNavBreakpoint = 'xl'

  return (
    <AppBar
      elevation={0}
      position={scrollDir === 'up' ? 'sticky' : 'relative'}
      color="inherit"
      sx={(theme) => ({
        zIndex: theme.zIndex.modal,
        boxShadow: '0px 0px 10px 0px rgba(75, 75, 75, 0.18)',
        '@keyframes slideIn': {
          from: {
            transform: 'translateY(-100px)',
          },
          to: {
            transform: 'translateY(0)',
          },
        },
        animation: scrollDir === 'up' ? 'slideIn 0.5s linear' : 'none',
      })}
    >
      <Toolbar
        disableGutters
        sx={(theme) => ({
          backgroundColor: theme.palette.background.main,
          padding: theme.spacing(4, 8, 4, 4),
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          [theme.breakpoints.up(mobileNavBreakpoint)]: {
            padding: theme.spacing(6, 8),
            boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.12)',
          },
        })}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <React.Fragment>
            <Grid
              container
              item
              xs={2}
              alignItems="center"
              justifyContent="flex-start"
              direction="row"
              nofill={true}
              component={Link}
              to="/"
            >
              <SvgIcon
                sx={(theme) => ({
                  width: 'auto',
                  height: '100%',
                  maxHeight: '60px',
                  [theme.breakpoints.up(mobileNavBreakpoint)]: {
                    maxHeight: '85px',
                  },
                })}
                component={HomeLogo as React.ElementType<unknown>}
                viewBox="0 0 394 281"
              />
            </Grid>
            <Grid
              container
              item
              xs={10}
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
            >
              {/* Navigation */}
              <Box
                sx={(theme) => ({
                  display: 'none',
                  flexFlow: 'row nowrap',
                  [theme.breakpoints.down(mobileNavBreakpoint)]: navOpen && {
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    alignItems: 'left',
                    position: 'absolute',
                    top: '92px',
                    right: 0,
                    padding: theme.spacing(10, 12, 12, 12),
                    backgroundColor: theme.palette.common.white,
                    zIndex: '-1',
                  },
                  [theme.breakpoints.up(mobileNavBreakpoint)]: {
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                  },
                  '& > :last-child': {
                    margin: theme.spacing(8, 0, 0),
                    [theme.breakpoints.up(mobileNavBreakpoint)]: {
                      margin: theme.spacing(0, 8),
                    },
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.up(mobileNavBreakpoint)]: {
                      paddingRight: theme.spacing(16),
                    },
                  })}
                >
                  <HorizontalNav
                    items={navigationItems}
                    breakpoint={mobileNavBreakpoint}
                    onClick={() => setNavOpen(false)}
                  />
                </Box>
                {/* <Box
                  sx={(theme) => ({
                    [theme.breakpoints.up(mobileNavBreakpoint)]: {
                      paddingRight: theme.spacing(12),
                    },
                  })}
                >
                  <Button to="/register" onClick={() => setNavOpen(false)}>
                    {translated.global.header.registration}
                  </Button>
                </Box> */}
                <Box
                  sx={(theme) => ({
                    display: 'none',
                    [theme.breakpoints.up(mobileNavBreakpoint)]: {
                      display: 'block',
                      paddingRight: theme.spacing(12),
                    },
                    [theme.breakpoints.up('xxl')]: {
                      paddingRight: theme.spacing(30),
                    },
                    '& button': {
                      fontSize: '18px',
                    },
                  })}
                >
                  <LanguageSwitcher />
                </Box>
                <Box>
                  <a
                    href={translated.global.header.rightLogoLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SvgIcon
                      sx={(theme) => ({
                        width: 'auto',
                        height: theme.spacing(10),
                      })}
                      component={mfundLogo as React.ElementType<unknown>}
                      viewBox="0 0 122 41"
                    />
                  </a>
                </Box>
              </Box>

              {/* Mobile language picker */}
              <Box
                sx={(theme) => ({
                  display: 'block',
                  marginRight: theme.spacing(6),
                  [theme.breakpoints.up(mobileNavBreakpoint)]: {
                    display: 'none',
                  },
                  '& button': {
                    fontSize: '18px',
                  },
                })}
              >
                <LanguageSwitcher />
              </Box>

              {/* Burger Menu */}
              <Burger
                active={navOpen}
                breakpoint={mobileNavBreakpoint}
                onClick={() => setNavOpen((navOpen) => !navOpen)}
              />
            </Grid>
          </React.Fragment>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
