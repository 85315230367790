import React, { ReactElement } from 'react'
import { ButtonBase as MuiButton } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import { useLanguageContext } from '@system/providers/LanguageProvider'

export type ButtonProps = BASE.Helpers.IReactDefaultProps & {
  id?: string
  to?: string
  onClick?: (event: React.SyntheticEvent) => void
  isExternal?: boolean
  disabled?: boolean
  variant?: 'primary' | 'secondary'
  type?: string
  isCountdownButton?: boolean
}

export default function Button({
  id,
  to,
  onClick,
  children,
  isExternal,
  disabled,
  variant = 'primary',
  type,
  isCountdownButton,
  sx,
}: ButtonProps): ReactElement {
  const { lang } = useLanguageContext()

  const LinkComponent = !isExternal
    ? GatsbyLink
    : !to && onClick
    ? 'button'
    : 'a'

  // Localize the paths of internal links
  if (to && !isExternal) {
    to = lang === 'de' ? to : `/${lang}${to}`
  }

  const additionalProps = isExternal
    ? { href: to, target: '_blank', rel: 'noreferrer' }
    : { to }

  return (
    <MuiButton
      component={LinkComponent}
      disabled={disabled}
      id={id}
      onClick={onClick}
      type={type}
      target={isCountdownButton ? '_blank' : '_self'}
      {...additionalProps}
      sx={[
        (theme) => ({
          ...theme.typography.button,
          color:
            variant === 'primary'
              ? theme.palette.common.white
              : theme.palette.primary.main,
          borderRadius: '8px',
          lineHeight: '16px',
          padding: theme.spacing(4, 9, 4),
          transition: '0.3s',
          backgroundColor: theme.palette[variant].main + ' !important',
          [theme.breakpoints.up('lg')]: {
            '&:hover': {
              filter: 'brightness(150%)',
            },
          },
          '&.Mui-disabled': {
            border: 'none',
            color: theme.palette.grey[300],
            backgroundColor: theme.palette.grey[100] + ' !important',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </MuiButton>
  )
}
