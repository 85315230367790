import React, { ReactElement } from 'react'
import { Container } from '@mui/system'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

interface FAQQuestion {
  question: string
  answer: string
}

interface FAQEntry {
  headline: string
  questions: FAQQuestion[]
}

interface FAQData {
  headline: string
  copy: string
  entries: FAQEntry[]
}

export default function FAQ({
  headline,
  copy,
  entries,
}: FAQData): ReactElement {
  return (
    <Container
      component="section"
      maxWidth={false}
      sx={(theme) => ({
        backgroundColor: 'rgba(247, 247, 247, 1)',
        pb: theme.spacing(16),
        [theme.breakpoints.up('lg')]: {
          pb: theme.spacing(25),
        },
      })}
    >
      <Container component="section" maxWidth="lg">
        <Grid container xs={12}>
          <Typography variant="h1" gutterBottom mt={18}>
            {headline}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            mt={'30px'}
            dangerouslySetInnerHTML={{ __html: copy }}
          />
        </Grid>
      </Container>

      <Container component={'section'} maxWidth="lg" sx={{ mt: 16 }}>
        {Object.entries(entries).map(([key, value]) => {
          return (
            <Grid
              key={key}
              container
              xs={12}
              sx={(theme) => ({
                '&:not(:last-child)': {
                  mb: theme.spacing(12),
                  [theme.breakpoints.up('lg')]: {
                    mb: theme.spacing(16),
                  },
                },
              })}
            >
              <Grid xs={12}>
                <Typography variant="h2" gutterBottom mb={'24px'}>
                  {value.headline}
                </Typography>
              </Grid>
              {Object.entries(value.questions).map(([k, entry]) => {
                return (
                  <Grid key={k} xs={12}>
                    <Accordion
                      sx={{
                        backgroundColor: 'white',
                        border: 'none',
                        p: 6,
                      }}
                      disableGutters
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{ color: 'rgba(0, 83, 142, 1)' }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5" fontWeight={700}>
                          {entry.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{ __html: entry.answer }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </Container>
    </Container>
  )
}
