import React, { useState } from 'react'

import { Box, Typography } from '@mui/material'

import Link from '@components/core/link'
import { INavItemData } from '@components/modules/global/navigation/drawerNav'
import NavItemLevel3 from './navitemLevel3'
// import useGlobalText from '@system/hooks/useGlobalText'

export interface INavItem {
  item: INavItemData
  closeNavWindow: () => void
}

export default function NavItemLevel2({ item, closeNavWindow }: INavItem) {
  // const { getText } = useGlobalText()
  const [isHoveredLvl2, setIsHoveredLvl2] = useState(false)
  function renderSubNavi() {
    if (!item.subPages) return null

    return (
      <Box
        component="ul"
        sx={(theme) => ({
          position: 'absolute',
          left: '100%',
          minWidth: '400px',
          minHeight: '100%',
          top: 0,
          display: isHoveredLvl2 ? 'block' : 'none',
          listStyle: 'none',
          margin: 0,
          padding: theme.spacing(10, 10, 6, 10),
          zIndex: -1,
          backgroundColor: theme.palette.background.light,
          borderRadius: `0px 0px 20px 20px`,
        })}
      >
        {!!item.fields?.fullPath && (
          <NavItemLevel3
            item={{
              id: item.id,
              titleNavigation:
                getText(`navigation.label.overview.${item.url}`) ||
                getText(`navigation.label.overview.default`),
              fields: item.fields,
              url: item.url,
            }}
            closeNavWindow={closeNavWindow}
          />
        )}
        {item.subPages
          .filter((item) => !item.hideInNavigation)
          .map((item) => {
            return (
              <NavItemLevel3
                key={item.id}
                item={item}
                closeNavWindow={closeNavWindow}
              />
            )
          })}
      </Box>
    )
  }

  return (
    <Box
      component="li"
      onMouseEnter={() => setIsHoveredLvl2(true)}
      onMouseLeave={() => setIsHoveredLvl2(false)}
      onClick={() => {
        if (!item.subPages) {
          closeNavWindow()
        }
      }}
      role="none"
    >
      <Typography
        component={Link}
        variant="navigation"
        linkColor="black"
        to={!!item.subPages ? undefined : item.fields?.fullPath}
        icon={
          !!item.subPages
            ? {
                name: 'ChevronRight',
                // TODO: FIXME
                sx: (theme) => ({
                  marginLeft: theme.spacing(15),
                  marginRight: theme.spacing(5),
                }),
              }
            : undefined
        }
        sx={(theme) => ({
          zIndex: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: !!item.subPages ? '100%' : 'fit-content',
          textAlign: 'left',
          padding: !!item.subPages
            ? theme.spacing(1.5, 0, 1.5, 13)
            : theme.spacing(2, 4),
          marginX: !!item.subPages ? '0' : theme.spacing(6),
          borderRadius: !!item.subPages ? '0 18px 18px 0' : '18px',
          transition: '0.2s',
          whiteSpace: 'nowrap',
          marginBottom: theme.spacing(2),
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            marginTop: '3px',
          },
          [theme.breakpoints.up('lg')]: {
            '&:focus, &:hover': {
              backgroundColor: theme.palette.background.red,
              color: theme.palette.text.inverted,
              width: !!item.subPages ? 'calc(100% + 16px)' : 'fit-content',
              '& .MuiSvgIcon-root': {
                '& path': {
                  fill: theme.palette.text.inverted,
                },
              },
            },
          },
        })}
      >
        {item.titleNavigation}
      </Typography>
      {renderSubNavi()}
    </Box>
  )
}
