import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Container, Typography, Paper, Box, Link } from '@mui/material'
import Icon from '@components/core/icon'

/**
 * Generates a registration URL for a team.
 * @param {string} teamname - name of the team
 * @param {string} mfund    - mFUND project acronyme
 * @returns {string}
 */
const generateLink = (teamname: string, mfund: string): string => {
  const queryParams = [
    teamname && `teamname=${encodeURIComponent(teamname)}`,
    mfund && `mfund=${encodeURIComponent(mfund)}`,
  ]
    .filter(Boolean)
    .join('&')

  return queryParams ? `/register?${queryParams}` : ''
}

/**
 * Used for replacing the generated registration link in the translation files.
 * @param {string} str - The string containing the wildcard, e.g. "Dear %name%"
 * @param {string} wildcard - The wildcard, e.g. "%name%"
 * @param {string} replacement - The replacement, e.g. "Daniel"
 * @returns {string} - The replaced string, e.g. "Dear Daniel"
 */
const replaceWildcard = (
  str: string,
  wildcard: string,
  replacement: string
): string => {
  return str.replace(new RegExp(wildcard, 'g'), replacement)
}

/**
 * Checks the UserID with the API for validity.
 * @param {string} uid - The UserID, which has to be checked
 */
const checkUserId = async (uid: string) => {
  try {
    const response = await fetch(
      `${process.env.GATSBY_SWAGGER_UI_API_URL}/participants/${uid}/confirm-participation`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const body = await response.json()
    return { status: response.status, body: body }
  } catch (error) {
    console.error('Error:', error)
  }

  return { status: null, body: null }
}

export default function Optin() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  const [link, setLink] = useState('')

  const [verificationStatus, setVerification] = useState({
    message: '',
    type: '',
  })

  const [onWaitingList, setOnWaitinglist] = useState(false)

  // Get URL Parameters, generate the registration link and verify the participation
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search)
      const uid = urlParams.get('userId')
      const teamname = urlParams.get('teamname')
      const mfund = urlParams.get('mfund')

      setLink(generateLink(teamname, mfund))

      if (uid !== null && uid.length > 0) {
        /**
         * Handle the user's status check:
         * 200: All good. Check if user has been added to the waiting list.
         * 400:
         *  1. possible code 0 (user already verified) or 1 (user waited to long to verify) in body
         *  2. possible validationErrors in body for invlaid user ID
         * 404: unknown user ID
         */
        const handleUserCheck = async () => {
          const result = await checkUserId(uid)
          const { status = null, body = {} } = result
          const {
            waitingList = false,
            commonErrors = [],
            validationErrors = {},
          } = body
          const isNumber = typeof status === 'number' && !isNaN(status)
          const firstCommonError =
            commonErrors.length > 0 ? commonErrors[0] : null // Assuming there is ever only one entry
          const { Code: code = false } = firstCommonError ?? {}

          let message = translated.pages.optin.verification.other

          if (status === 200 && waitingList) {
            message = translated.pages.optin.verification.waitingList
          } else if (status === 200) {
            message = translated.pages.optin.verification[status]
          } else if (status === 400 && code !== false) {
            message = translated.pages.optin.verification[status][code]
          } else if (status === 400 && validationErrors.userId?.length > 0) {
            message = translated.pages.optin.verification[status].validation
          } else if (isNumber) {
            message = translated.pages.optin.verification[status]
          }

          setOnWaitinglist(waitingList)
          setVerification({
            message,
            type:
              isNumber && (status === 202 || status === 200)
                ? 'success'
                : 'error',
          })
        }
        handleUserCheck()
      } else {
        setVerification({
          message: translated.pages.optin.verification.noUser,
          type: 'error',
        })
      }
    }
  }, [])

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        flex: 1,
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="md" id="headline">
        <Typography variant="h1" gutterBottom>
          {translated.pages.optin.headline.headline}
        </Typography>
      </Container>
      <Container
        component="section"
        maxWidth="md"
        sx={(theme) => ({ mb: theme.spacing(16) })}
      >
        {ReactHtmlParser(translated.pages.optin.content.text)}
        {verificationStatus.message !== '' && (
          <Typography
            variant="body1"
            sx={(theme) => ({
              mb: 6,
              mt: 6,
              color:
                verificationStatus.type == 'error' &&
                theme.palette.common.red[500],
              fontWeight: verificationStatus.type == 'success' && 600,
            })}
            dangerouslySetInnerHTML={{ __html: verificationStatus.message }}
          ></Typography>
        )}
      </Container>

      {!onWaitingList && (
        <div>
          <Container component="section" maxWidth="md" id="headline">
            <Typography variant="h3" sx={{ mb: 6 }}>
              {translated.pages.optin.headline.headline2}
            </Typography>
          </Container>
          <Container component="section" maxWidth="md" id="headline">
            {Object.entries(translated.pages.optin.content.boxes).map(
              ([key, value]) => {
                if (replaceWildcard(value.url, '%link%', link).length > 0) {
                  return (
                    <Paper
                      elevation={4}
                      key={key}
                      sx={(theme) => ({
                        position: 'relative',
                        textAlign: 'center',
                        height: '100%',
                        lineHeight: '60px',
                        borderRadius: '12px',
                        padding: '20px 100px 24px 32px',
                        mb: 6,
                        [theme.breakpoints.down('sm')]: {
                          paddingRight: '75px',
                        },
                      })}
                    >
                      <Box
                        sx={{
                          pl: 1,
                          textAlign: 'left',
                        }}
                      >
                        <Typography
                          variant="body2"
                          component="p"
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          {value.headline}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          pl: 1,
                          textAlign: 'left',
                        }}
                      >
                        <Typography variant="body2" component="p">
                          {ReactHtmlParser(
                            replaceWildcard(value.content, '%link%', link)
                          )}
                        </Typography>
                      </Box>

                      <Link
                        href={replaceWildcard(value.url, '%link%', link)}
                        sx={(theme) => ({
                          position: 'absolute',
                          display: 'block',
                          width: '100px',
                          top: 0,
                          bottom: 0,
                          right: 0,
                          [theme.breakpoints.down('sm')]: {
                            width: '75px',
                          },
                        })}
                      >
                        <Icon
                          name="ChevronRightSlim"
                          color="primary"
                          sx={(theme) => ({
                            position: 'absolute',
                            height: '30px',
                            right: '30px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            [theme.breakpoints.down('sm')]: {
                              right: '25px',
                            },
                          })}
                        />
                      </Link>
                    </Paper>
                  )
                }
              }
            )}
          </Container>
        </div>
      )}
    </Container>
  )
}
