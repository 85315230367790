import React from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Container } from '@mui/system'
import {
  // Box,
  // Paper,
  Typography,
} from '@mui/material'

import Grid from '@mui/material/Unstable_Grid2/Grid2'

import Hero from '@components/modules/content/hero/hero'
import Challenges from '@components/core/challenges'
//import Button from '@components/core/button'
import FAQ from '@components/core/faq'
import Link from '@components/core/link'

export default function RootIndex() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  return (
    <main>
      <Container component="section" maxWidth={false} disableGutters>
        <Hero
          patternBottom="/img/logos/data-pattern-hero-bottom.svg"
          image="/img/pictures/city_stage.jpg"
          imageAltText={translated.pages.index.hero.imageAltText}
          preHeadline={translated.pages.index.hero.preHeadline}
          headline={translated.pages.index.hero.headline}
          subHeadline={translated.pages.index.hero.subHeadline}
          buttonText={translated.pages.index.hero.buttonText}
          buttonLink="/register"
          bullets={translated.pages.index.hero.bullets}
        ></Hero>
      </Container>

      <Container
        component="section"
        maxWidth="lg"
        sx={{ marginBottom: '84px' }}
      >
        <Grid container xs={12} rowSpacing={12}>
          <Grid container xs={12}>
            {!!translated.pages.index.intro.announcement && (
              <React.Fragment>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={(theme) => ({
                    color: theme.palette.text.red,
                    fontWeight: 'bold',
                    marginTop: theme.spacing(10),
                    [theme.breakpoints.up('lg')]: {
                      marginTop: theme.spacing(26),
                    },
                  })}
                >
                  {translated.pages.index.intro.announcement.title}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={(theme) => ({
                    color: theme.palette.text.red,
                  })}
                >
                  {translated.pages.index.intro.announcement.text}
                  <Typography
                    component={Link}
                    isExternal={true}
                    to={translated.pages.index.intro.announcement.link.source}
                    sx={(theme) => ({
                      color: theme.palette.text.red,
                    })}
                  >
                    {translated.pages.index.intro.announcement.link.text}
                  </Typography>
                </Typography>
              </React.Fragment>
            )}
            <Typography
              variant="h1"
              gutterBottom
              sx={(theme) => ({
                marginTop: theme.spacing(10),
                [theme.breakpoints.up('lg')]: {
                  marginTop: theme.spacing(26),
                },
              })}
            >
              {translated.pages.index.intro.headline}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              mt={'30px'}
              mb={'30px'}
              dangerouslySetInnerHTML={{
                __html: translated.pages.index.intro.copy,
              }}
            ></Typography>
            {/* <Typography
              variant="body2"
              gutterBottom
              mt={'30px'}
              mb={'30px'}
              dangerouslySetInnerHTML={{
                __html: translated.pages.index.intro.copy2,
              }}
            ></Typography> */}
          </Grid>

          <Challenges data={translated.pages.index.challenges} />

          {/* <Grid container xs={12}>
            <Typography variant="h1" gutterBottom mt={'110px'}>
              {translated.pages.index.registration.headline}
            </Typography>
            <Typography variant="body2" gutterBottom mt={'30px'}>
              {translated.pages.index.registration.copy}
            </Typography>
          </Grid>

          <Grid
            container
            xs={12}
            columnSpacing={{ sm: 4 }}
            rowSpacing={4}
            pt={'40px'}
          >
            <Grid md={4} xs={12}>
              <Paper
                elevation={4}
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  lineHeight: '60px',
                  borderRadius: '12px',
                  p: 6,
                }}
              >
                <Box
                  sx={{
                    pl: 1,
                    display: 'grid',
                    gridTemplateColumns: '25px 1fr',
                    gap: 3,
                    alignItems: 'center',
                    textAlign: 'left',
                    height: '46px',
                  }}
                >
                  <Typography
                    component="p"
                    fontSize="52px"
                    sx={{ m: 0 }}
                    color="rgba(0, 83, 142, 1)"
                    lineHeight="27px !important"
                  >
                    1.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{
                      m: 0,
                      ml: 4,
                      fontWeight: 'bold',
                      lineHeight: '20px !important',
                    }}
                  >
                    {translated.pages.index.registration.step1}
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid md={4} xs={12}>
              <Paper
                elevation={4}
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  lineHeight: '60px',
                  borderRadius: '12px',
                  p: 6,
                }}
              >
                <Box
                  sx={{
                    pl: 1,
                    display: 'grid',
                    gridTemplateColumns: '25px 1fr',
                    gap: 3,
                    alignItems: 'center',
                    textAlign: 'left',
                    height: '46px',
                  }}
                >
                  <Typography
                    component="p"
                    fontSize="52px"
                    sx={{ m: 0 }}
                    color="rgba(0, 83, 142, 1)"
                    lineHeight="27px !important"
                  >
                    2.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{
                      m: 0,
                      ml: 4,
                      fontWeight: 'bold',
                      lineHeight: '20px !important',
                    }}
                  >
                    {translated.pages.index.registration.step2}
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid md={4} xs={12}>
              <Paper
                elevation={4}
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  lineHeight: '60px',
                  borderRadius: '12px',
                  p: 6,
                }}
              >
                <Box
                  sx={{
                    pl: 1,
                    display: 'grid',
                    gridTemplateColumns: '25px 1fr',
                    gap: 3,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'left',
                    height: '46px',
                  }}
                >
                  <Typography
                    component="p"
                    sx={{ m: 0 }}
                    color="rgba(0, 83, 142, 1)"
                    fontSize="52px"
                    lineHeight="27px !important"
                  >
                    3.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{
                      m: 0,
                      ml: 4,
                      fontWeight: 'bold',
                      lineHeight: '20px !important',
                    }}
                  >
                    {translated.pages.index.registration.step3}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Grid container xs={12} pt={'45px'}>
            <Button to="/register">
              {translated.pages.index.registration.cta}
            </Button>
          </Grid> */}
        </Grid>
      </Container>

      <FAQ
        entries={translated.pages.index.faq.entries}
        headline={translated.pages.index.faq.headline}
        copy={translated.pages.index.faq.copy}
      ></FAQ>

      {/* Section Our Data */}
      <Container
        component="section"
        maxWidth="lg"
        sx={{ marginBottom: '84px' }}
      >
        <Grid container>
          <Grid xs={12}>
            <Typography variant="h1" gutterBottom mt={'110px'}>
              {translated.pages.index.ourData.headline}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography
              variant="body2"
              gutterBottom
              mt={'10px'}
              dangerouslySetInnerHTML={{
                __html: translated.pages.index.ourData.copy,
              }}
            />
          </Grid>
        </Grid>
        <Container
          component="div"
          sx={(theme) => ({
            display: 'flex',
            flexFlow: 'column',
            [theme.breakpoints.up('md')]: {
              flexFlow: 'row wrap',
              justifyContent: 'center',
            },
            [theme.breakpoints.up('lg')]: {
              flexFlow: 'row',
              justifyContent: 'center',
            },
            alignItems: 'center',
            mt: 4,
            padding: '0 !important',

            '& img': {
              maxWidth: '150px',
              maxHeight: '114px',
              mt: 8,

              '&:not(:last-child)': {
                marginRight: theme.spacing(8),
              },
            },
          })}
        >
          <img src="/img/logos/daten_logo-baw.webp" />
          <img src="/img/logos/BSH-Logo.webp" />
          <img src="/img/logos/daten_logo-inrix.webp" hidden />
          <img src="/img/logos/daten_logo-mobidatabw.webp" />
          <img src="/img/logos/logo-mobilithek.png" />
          <img src="/img/logos/logo-mobilitydataspace.webp" />
          <img src="/img/logos/daten_logo-rivercloud.webp" />
          <img src="/img/logos/daten_logo-sim3s.webp" />
          <img src="/img/logos/daten_logo-urbandataplatform.webp" />
        </Container>
      </Container>
    </main>
  )
}
