import { Components, Theme } from '@mui/material'

import {
  BundesSansRegular,
  BundesSansBold,
  BundesSansMedium,
  BundesSansBoldRegularItalic,
} from '../../definitions/fontFamily'

export const CssBaselineOverrides = (
  theme: Theme
): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    '@font-face': [BundesSansRegular],
    fallBacks: [
      {
        '@font-face': BundesSansBold,
      },
      {
        '@font-face': BundesSansMedium,
      },
      {
        '@font-face': BundesSansBoldRegularItalic,
      },
    ],
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    strong: {
      fontWeight: 700,
    },
    button: {
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
    },
  },
})
