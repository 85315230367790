import React, { useRef, useState, useEffect, MouseEventHandler } from 'react'
import { useLocation } from '@reach/router'

import { Backdrop, Box, Grid } from '@mui/material'

import { INavItemData } from '@components/modules/global/navigation/drawerNav'
import NavItemLevel1 from './parts/navItemLevel1'

interface IHorizontalNav {
  items?: Array<INavItemData>
  breakpoint: string
  onClick: MouseEventHandler
}

export default function HorizontalNav({
  items,
  breakpoint,
  onClick,
}: IHorizontalNav) {
  const CompRef = useRef<HTMLElement>(null)
  const [isOpenId, setIsOpenId] = useState<string>()
  const location = useLocation()

  const handleNavItemLevel1Click = (event) => {
    onClick(event)
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    setIsOpenId(undefined)
  }, [location.pathname])

  const handleScroll = () => {
    if (window.pageYOffset >= 120) {
      setIsOpenId(undefined)
    }
  }
  return (
    <Box
      component="nav"
      ref={CompRef}
      sx={{
        height: 'auto',
      }}
    >
      <Backdrop
        open={!!isOpenId}
        onClick={() => setIsOpenId(undefined)}
        sx={() => ({ zIndex: -1 })}
      />
      <Grid
        component="ul"
        container
        direction="row"
        role="menubar"
        aria-label="Hauptnavigation"
        sx={(theme) => ({
          listStyle: 'none',
          margin: 0,
          padding: 0,
          alignItems: 'baseline',
          [theme.breakpoints.down(breakpoint)]: {
            flexFlow: 'column wrap',
            alignItems: 'left',
          },
        })}
      >
        {items
          ?.filter((item) => !item.hideInNavigation)
          .map((item) => {
            return (
              <NavItemLevel1
                key={item.id}
                item={item}
                breakpoint={breakpoint}
                isOpen={isOpenId === item.id}
                setIsOpenId={setIsOpenId}
                onClick={handleNavItemLevel1Click}
              />
            )
          })}
      </Grid>
    </Box>
  )
}
