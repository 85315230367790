import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { WidgetInstance } from 'friendly-challenge'

const Captcha = ({ onCaptchaVerified }) => {
  const container = useRef()
  const widget = useRef()

  const doneCallback = (solution) => {
    onCaptchaVerified(solution)
  }

  const errorCallback = (err) => {
    console.error(err)
  }

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: 'auto',
        doneCallback: doneCallback,
        errorCallback: errorCallback,
      })
    }

    return () => {
      if (widget.current != undefined) widget.current.reset()
    }
  }, [container])

  return (
    <div
      ref={container}
      className="frc-captcha"
      data-sitekey={process.env.GATSBY_FRIENDLYCAPTCHA_SITEKEY}
      data-puzzle-endpoint={process.env.GATSBY_FRIENDLYCAPTCHA_ENDPOINT_URL}
    ></div>
  )
}

Captcha.propTypes = {
  onCaptchaVerified: PropTypes.func.isRequired,
}

export default Captcha
