import React, { ReactElement } from 'react'

import { WrapPageElementNodeArgs } from 'gatsby'

import Layout from '@components/modules/global/layout'

import { ThemeProvider, CssBaseline } from '@mui/material'

import LanguageProvider from '@system/providers/LanguageProvider'

import theme from '@config/theme'

type PageWrapperProps = WrapPageElementNodeArgs & {
  props: {
    pageContext: BASE.Helpers.IPageContext
    location: Record<string, boolean>
  }
}

export default function PageWrapper({
  element,
  props,
}: PageWrapperProps): ReactElement {
  const langFromPath = props.location.pathname.startsWith('/en') ? 'en' : 'de'

  return (
    <React.Fragment>
      <LanguageProvider initialLang={langFromPath}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout pageContext={props.pageContext}>{element}</Layout>
        </ThemeProvider>
      </LanguageProvider>
    </React.Fragment>
  )
}
