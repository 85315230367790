import { Components, Theme } from '@mui/material'
import { alpha } from '@mui/system'

export const MuiFormControlOverrides = (): Components['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      width: '100%',
    },
  },
})

export const MuiFormControlLabelOverrides = (
  theme: Theme
): Components['MuiFormControlLabel'] => ({
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: theme.palette.grey[500],
        WebkitTextFillColor: theme.palette.grey[500],
        cursor: 'auto',
      },
    },
  },
})

export const MuiInputBaseOverrides = (
  theme: Theme
): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: {
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
      '& .MuiSelect-nativeInput': {
        opacity: 1,
        top: '2px',
        background: 'transparent',
        paddingLeft: theme.spacing(6),
        '&:not(:placeholder-shown)': {
          opacity: 0,
        },
      },
    },
  },
})

export const MuiOutlinedInputOverrides = (
  theme: Theme
): Components['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(6),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(6),
      height: 'auto',
      border: '2px solid rgba(0, 0, 0, 0.3)',
      borderRadius: '12px',
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiSelect-select': {
        minHeight: '19px !important',
      },
      '&.Mui-disabled': {
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[100],
      },
    },
    input: {
      cursor: 'pointer',
      padding: 0,
      height: 'auto',
      lineHeight: '19px',
      '&.Mui-disabled': {
        color: theme.palette.grey[500],
        WebkitTextFillColor: theme.palette.grey[500],
        cursor: 'auto',
      },
    },
    notchedOutline: {
      border: 0,
      '& fieldset': {
        display: 'none',
      },
    },
  },
})

export const MuiFilledInputOverrides = (
  theme: Theme
): Components['MuiFilledInput'] => ({
  styleOverrides: {
    root: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '&:before': {
        borderBottom: '1px solid',
      },
      '&:after': {
        borderBottom: '1px solid',
      },
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    input: {
      cursor: 'pointer',
      padding: 0,
    },
  },
})

export const MuiFormHelperTextOverrides = (
  theme: Theme
): Components['MuiFormHelperText'] => ({
  styleOverrides: {
    root: {
      marginLeft: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
  },
})

export const MuiInputLabelOverrides = (
  theme: Theme
): Components['MuiInputLabel'] => ({
  styleOverrides: {
    root: {
      position: 'relative',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '19px',
      transform: 'none',
      zIndex: 1,
      cursor: 'pointer',
      marginBottom: theme.spacing(1),
      color: theme.palette.common.black,
      '&.Mui-focused': {
        transform: 'none',
        color: theme.palette.common.black,
      },
      '&.Mui-error': {
        color: theme.palette.grey[500],
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[700],
      },

      // I know, not nice. But couldn't find a better way to override the component's placeholder default style.
      '&[data-shrink="false"] + .MuiInputBase-formControl .MuiInputBase-input::placeholder':
        {
          opacity: '1 !important',
          color: theme.palette.grey[500],
        },
      '&[data-shrink="true"] + .MuiInputBase-formControl .MuiInputBase-input::placeholder':
        {
          opacity: '1 !important',
          color: theme.palette.grey[500],
        },
    },
    asterisk: {
      //color: theme.palette.primary.main,
    },
  },
})
