import React, { ReactElement } from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'
import { Container } from '@mui/system'
import { Typography } from '@mui/material'

export default function RootIndex(): ReactElement {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        flex: 1,
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="md" id="headline">
        <Typography variant="h1" gutterBottom>
          {translated.pages.page404.heading}
        </Typography>
      </Container>
      <Container
        component="section"
        maxWidth="md"
        sx={(theme) => ({ mb: theme.spacing(16) })}
        dangerouslySetInnerHTML={{
          __html: translated.pages.page404.copy,
        }}
      />
    </Container>
  )
}
