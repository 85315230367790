import React, { MouseEventHandler, useMemo, useState } from 'react'
import { useLocation } from '@reach/router'

import { Box, Typography } from '@mui/material'

// import { useLanguageContext } from '@system/providers/LanguageProvider';
// import { localizedLink } from '@system/utils/localizedLink';

import Link from '@components/core/link'
import { INavItemData } from '@components/modules/global/navigation/drawerNav'
import NavItemLevel2 from './navitemLevel2'
// import useGlobalText from '@system/hooks/useGlobalText'

export interface INavItem {
  item: INavItemData
  isOpen: boolean
  breakpoint: string
  setIsOpenId: React.Dispatch<React.SetStateAction<string | undefined>>
  onClick: MouseEventHandler
}

export default function NavItemLevel1({
  item,
  isOpen,
  breakpoint,
  setIsOpenId,
  onClick,
}: INavItem) {
  // const { getText } = useGlobalText()
  // const { lang } = useLanguageContext();

  const handleClick = (event) => {
    onClick(event)
  }

  const location = useLocation()
  const [isHoveredLvl1, setIsHoveredLvl1] = useState(false)
  const hasThirdLevel = useMemo(
    () => item.subPages?.find((sp) => !!sp.subPages),
    []
  )
  function renderSubNavi() {
    if (!item.subPages) return null
    return (
      <Box
        component="ul"
        onMouseLeave={() => setIsHoveredLvl1(false)}
        sx={(theme) => ({
          display: isOpen ? 'block' : 'none',
          listStyle: 'none',
          margin: 0,
          position: 'absolute',
          top: 'calc(100% + 32px)',
          right: '-50%',
          padding: theme.spacing(10, 0, 8, 0),
          backgroundColor: theme.palette.background.main,
          borderRadius: `0px 0px ${hasThirdLevel ? '0px' : '20px'} 20px`,
          minWidth: hasThirdLevel ? '400px' : '0px',
          boxShadow:
            '0px 10px 10px -15px rgba(0, 0, 0, 0.15), -10px 10px 10px -10px rgba(0, 0, 0, 0.15), 10px 10px 10px -10px rgba(0, 0, 0, 0.15)',
          height: !!item.subPages && hasThirdLevel ? '412px' : 'auto',
        })}
      >
        {!!item.fields?.fullPath && (
          <NavItemLevel2
            item={{
              id: item.id,
              titleNavigation:
                getText(`navigation.label.overview.${item.url}`) ||
                getText(`navigation.label.overview.default`),
              fields: item.fields,
              url: item.url,
            }}
            closeNavWindow={() => {
              setIsHoveredLvl1(false)
              setIsOpenId(undefined)
            }}
          />
        )}
        {!!hasThirdLevel && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              zIndex: -1,
              left: '100%',
              top: 0,
              bottom: 0,
              minWidth: '400px',
              backgroundColor: theme.palette.background.light,
              borderRadius: `0px 0px 20px 0px`,
            })}
          />
        )}
        {item.subPages
          .filter((item) => !item.hideInNavigation)
          .map((item) => {
            return (
              <NavItemLevel2
                key={item.id}
                item={item}
                closeNavWindow={() => {
                  setIsHoveredLvl1(false)
                  setIsOpenId(undefined)
                }}
              />
            )
          })}
      </Box>
    )
  }

  return (
    <Box
      component="li"
      onMouseEnter={() => {
        if (!!item.subPages) {
          setIsHoveredLvl1(true)
          setIsOpenId(isHoveredLvl1 ? undefined : item.id)
        }
      }}
      onMouseLeave={() => {
        setIsHoveredLvl1(false)
        setIsOpenId(undefined)
      }}
      role="none"
      sx={(theme) => ({
        position: 'relative',
        transition: 'color .2s',
        marginBottom: theme.spacing(8),
        [theme.breakpoints.up(breakpoint)]: {
          marginBottom: 0,
          '&:not(:first-of-type)': {
            marginLeft: theme.spacing(18),
          },
        },
        ...(location.pathname.includes(item.fields?.fullPath) && {
          color: theme.palette.tertiary.dark,
          '& .MuiSvgIcon-root': {
            color: theme.palette.tertiary.dark,
          },
        }),
      })}
    >
      <Typography
        component={Link}
        linkColor="black"
        variant="navigation"
        // to={!!item.subPages ? undefined : localizedLink(lang, item.fields?.fullPath)}
        to={!!item.subPages ? undefined : item.fields?.fullPath}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          '& + ul': {
            display: isHoveredLvl1 ? 'block' : 'none',
          },
          '&:hover': {
            '& > svg': {
              transform: 'scale(1, -1)',
              transition: 'transform .2s',
            },
          },
          lineHeight: 1,
          '& > svg': {
            transform: isOpen ? 'scale(1, -1)' : 'scale(1)',
            transition: 'transform .2s',
          },
        }}
        icon={!!item.subPages ? { name: 'ChevronDown' } : undefined}
      >
        {item.titleNavigation}
      </Typography>
      {renderSubNavi()}
    </Box>
  )
}
