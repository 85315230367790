import React, { ReactElement } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import Link from '@components/core/link'
import PlusIcon from '@static/img/icons/icon_plus_circle.inline.svg'

interface Challenge {
  headline1: string
  headline2: string
  copy: string
  image: string
  imageMobile: string
  link: {
    url: string
    label: string
  }
}

interface ChallengeProps {
  data: Challenge[]
}

export default function Challenges({ data }: ChallengeProps): ReactElement {
  return (
    <Grid container xs={12} spacing={6}>
      {Object.entries(data).map(([key, value]) => {
        return (
          <Grid xs={12} key={key}>
            <Accordion
              elevation={0}
              sx={{
                padding: '0 !important',
                marginBottom: '0 !important',
                color: 'white',
                border: 'none',
                backgroundColor: 'rgba(0, 83, 142, 1)',
              }}
            >
              <AccordionSummary
                expandIcon={<PlusIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={(theme) => ({
                  backgroundImage: `url('${value.imageMobile}')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '192px',
                  p: 6,
                  borderRadius: '12px',

                  [theme.breakpoints.up('md')]: {
                    backgroundImage: `url('${value.image}')`,
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    color: theme.palette.common.white,
                  },
                })}
              >
                <Grid>
                  <Typography
                    variant="h2"
                    sx={{ marginBottom: '16px !important' }}
                  >
                    {value.headline1}
                  </Typography>
                  <Typography component="span" variant="h5">
                    {value.headline2}
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                sx={(theme) => ({
                  padding: '24px 40px !important',

                  [theme.breakpoints.up('md')]: {
                    padding: '32px 48px 68px !important',
                  },
                })}
              >
                <Typography
                  variant="body2"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: value.copy }}
                  sx={(theme) => ({
                    '& h4': {
                      fontWeight: 'bold',
                    },
                    '& p:not(:last-child)': {
                      marginBottom: theme.spacing(6),
                    },
                  })}
                ></Typography>

                {value.link?.label && value.link?.url && (
                  <Link
                    to={value.link.url}
                    linkColor="inverted"
                    icon={{ name: 'ArrowCircled', fontSize: 'medium' }}
                    iconLeft={true}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                      fontWeight: '700',
                      fontSize: '20px',
                      textDecoration: 'underline',
                      display: 'block',
                      marginTop: theme.spacing(6),
                    })}
                  >
                    {value.link.label}
                  </Link>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      })}
    </Grid>
  )
}
