import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Container, Typography } from '@mui/material'
import IconAccordion from '@components/core/iconAccordion'

interface AccordionEntry {
  headline1: string
  headline2: string
}

export default function Daten() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)
  const accordionEntries: AccordionEntry[] =
    translated.pages.daten.accordionEntries

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        flex: 1,
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="lg" id="headline">
        <Typography variant="h1" gutterBottom>
          {translated.pages.daten.intro.headline}
        </Typography>
      </Container>

      {translated.pages.daten.intro.copy.length > 0 && (
        <Container component="section" maxWidth="lg">
          <Typography variant="body2">
            {ReactHtmlParser(translated.pages.daten.intro.copy)}
          </Typography>
        </Container>
      )}

      <Container component="section" maxWidth="lg" sx={{ mt: 16 }}>
        <IconAccordion data={accordionEntries} />
      </Container>
    </Container>
  )
}
