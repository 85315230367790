import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Container, Typography } from '@mui/material'
import ImageText from '@components/core/imageText'

interface Person {
  name: string
  bio: string
  image: {
    url: string
    alt: string
  }
}

export default function Jury() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)
  const juryMembers: Person[] = translated.pages.jury.members

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        flex: 1,
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="lg" id="headline">
        <Typography variant="h1" gutterBottom>
          {translated.pages.jury.intro.headline}
        </Typography>
      </Container>

      {translated.pages.jury.intro.copy.length > 0 && (
        <Container component="section" maxWidth="lg" sx={{ mb: 9 }}>
          <Typography variant="body2">
            {ReactHtmlParser(translated.pages.jury.intro.copy)}
          </Typography>
        </Container>
      )}

      <Container
        component="section"
        maxWidth="lg"
        sx={(theme) => ({
          marginTop: theme.spacing(11),
        })}
      >
        {Object.entries(juryMembers).map(([key, value]) => {
          return (
            <ImageText
              key={key}
              headline={value.name}
              text={value.bio}
              image={value.image}
            />
          )
        })}
      </Container>
    </Container>
  )
}
