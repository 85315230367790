import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Container, Typography } from '@mui/material'

export default function Contact() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        flex: 1,
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="md" id="headline">
        <Typography variant="h1" gutterBottom>
          {translated.pages.contact.headline}
        </Typography>
      </Container>
      <Container component="section" maxWidth="md" sx={{ mb: 9 }}>
        <Typography variant="body2">
          {ReactHtmlParser(translated.pages.contact.content.text)}
        </Typography>
      </Container>
      <Container component="section" maxWidth="md">
        <Typography variant="body2" sx={{ mb: 8 }}>
          <strong>{translated.pages.contact.content.contactData.name}</strong>
          <br />
          {ReactHtmlParser(
            translated.pages.contact.content.contactData.address
          )}
        </Typography>
        <Typography variant="body2">
          {ReactHtmlParser(translated.pages.contact.content.contactData.email)}
        </Typography>
      </Container>
    </Container>
  )
}
