import React, { useEffect, useState, useRef } from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import * as Yup from 'yup'
import ReactHtmlParser from 'react-html-parser'
import { navigate } from 'gatsby'
import { Box, Container, MenuItem, Typography } from '@mui/material'
import { Field, Form, Formik, ErrorMessage } from 'formik'
import { CheckboxWithLabel, TextField, Select } from 'formik-mui'
import Button from '@components/core/button'
import Captcha from '@components/core/captcha'

export default function RootRegistration() {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  const formRef = useRef(null)

  const [formSubmitted, setFormSubmitted] = useState(false)

  const [captchaSolution, setCaptchaSolution] = useState(null)

  const [teamFieldsDisabled, setTeamFieldsDisabled] = useState(false)

  const [formSubmitErrorMessage, setFormSubmitErrorMessage] = useState(null)

  const handleCaptchaVerified = (solution) => {
    setCaptchaSolution(solution)
  }

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(
      translated.pages.register.form.validation.firstname
    ),
    lastname: Yup.string().required(
      translated.pages.register.form.validation.lastname
    ),
    email: Yup.string()
      .email(translated.pages.register.form.validation.email.invalid)
      .required(translated.pages.register.form.validation.email.required),
    workingFor: Yup.string().required(
      translated.pages.register.form.validation.workingFor
    ),
    areaOfExpertise: Yup.string(),
    participateAs: Yup.string().required(
      translated.pages.register.form.validation.participateAs
    ),
    participateAsTeam: Yup.boolean(),
    teamname: Yup.string(),
    mFundAcronymVisible: Yup.boolean(),
    mFundAcronym: Yup.string(),
    foodPreference: Yup.string().required(
      translated.pages.register.form.validation.foodPreference
    ),
    teambuilding: Yup.boolean(),
    annotations: Yup.string(),
    mobiRegister: Yup.boolean().required(
      translated.pages.register.form.validation.mobiRegister
    ),
    dsgvo: Yup.boolean().required(
      translated.pages.register.form.validation.dsgvo
    ),
    adult: Yup.boolean().required(
      translated.pages.register.form.validation.adult
    ),
    newsletter: Yup.boolean(),
  })

  // Validation required for the checkboxes to work.
  // called via <Formik validate={validate}>
  const validate = (values) => {
    const errors = {}

    if (!values.mobiRegister) {
      errors.mobiRegister = 'Required'
    }

    if (!values.dsgvo) {
      errors.dsgvo = 'Required'
    }

    if (!values.adult) {
      errors.adult = 'Required'
    }

    return errors
  }

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        marginTop: theme.spacing(34),
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(47),
        },
      })}
    >
      <Container component="section" maxWidth="lg" id="headline">
        <Typography variant="h1" gutterBottom>
          {translated.pages.register.headline}
        </Typography>
      </Container>

      <Formik
        innerRef={formRef}
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          workingFor: '',
          areaOfExpertise: '',
          participateAs: '',
          participateAsTeam: false,
          teamname: '',
          mFundAcronymVisible: false,
          mFundAcronym: '',
          foodPreference: '',
          teambuilding: false,
          annotations: '',
          mobiRegister: false,
          dsgvo: false,
          adult: false,
          newsletter: false,
          captcha: '',
        }}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={async (
          values,
          { setSubmitting, setErrors, validateForm }
        ) => {
          const errors = await validateForm()

          if (Object.keys(errors).length > 0) {
            console.warn('validation failed', errors)
            return
          }

          if (captchaSolution) {
            values.captcha = captchaSolution

            try {
              const response = await fetch(
                `${process.env.GATSBY_SWAGGER_UI_API_URL}/participants`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(values),
                }
              )

              if (response.status === 202) {
                setSubmitting(false)
                navigate('/confirm')
              } else if ([400, 404, 500, 502, 503].includes(response.status)) {
                setFormSubmitErrorMessage(
                  translated.pages.register.form.errors.general
                )
              } else {
                throw new Error(`Unexpected status: ${response.status}`)
              }
            } catch (error) {
              console.error('Error:', error)
            }
            setFormSubmitted(true)
          } else {
            setErrors({
              captcha: 'Captcha not verified',
            })
          }
        }}
      >
        {({ values, setFieldValue, submitForm, isSubmitting }) => {
          // Update Team field values, according to URL Parameter
          useEffect(() => {
            if (typeof window !== 'undefined') {
              const urlParams = new URLSearchParams(window.location.search)
              const teamnameFromUrl = urlParams.get('teamname')
              const mFUNDIdFromUrl = urlParams.get('mfund')
              if (teamnameFromUrl) {
                setFieldValue('participateAsTeam', true)
                setFieldValue('teamname', teamnameFromUrl)
                setTeamFieldsDisabled(true)
              }
              if (mFUNDIdFromUrl) {
                setFieldValue('mFundAcronymVisible', true)
                setFieldValue('mFundAcronym', mFUNDIdFromUrl)
              }
            }
          }, [])

          return (
            <Form>
              {/* Personal Info Form Section */}
              <Container component="section" maxWidth="lg" id="form1">
                <Typography variant="h3" sx={{ mt: 16 }}>
                  {translated.pages.register.form.personal.headline}
                </Typography>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    [theme.breakpoints.up('md')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                    gridTemplateColumns: '1fr',
                    rowGap: theme.spacing(8),
                    columnGap: theme.spacing(6),
                  })}
                >
                  <Field
                    component={TextField}
                    name="firstname"
                    label={
                      translated.pages.register.form.personal.labels.firstname
                    }
                    placeholder={
                      translated.pages.register.form.personal.placeholders
                        .firstname
                    }
                    required
                  />
                  <Field
                    component={TextField}
                    name="lastname"
                    label={
                      translated.pages.register.form.personal.labels.lastname
                    }
                    placeholder={
                      translated.pages.register.form.personal.placeholders
                        .lastname
                    }
                    required
                  />
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label={translated.pages.register.form.personal.labels.email}
                    placeholder={
                      translated.pages.register.form.personal.placeholders.email
                    }
                    required
                  />
                </Box>

                {/* Job Form Section */}

                <Typography variant="h3" sx={{ mt: 16 }}>
                  {translated.pages.register.form.job.headline}
                </Typography>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    [theme.breakpoints.up('md')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                    gridTemplateColumns: '1fr',
                    rowGap: theme.spacing(8),
                    columnGap: theme.spacing(6),
                  })}
                >
                  <Field
                    component={Select}
                    id="workingFor"
                    name="workingFor"
                    labelId="workingFor-label"
                    label={translated.pages.register.form.job.labels.workingFor}
                    placeholder={
                      translated.pages.register.form.job.placheolders.workingFor
                    }
                  >
                    {Object.entries(
                      translated.pages.register.form.job.fields.workingFor
                    ).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      )
                    })}
                  </Field>
                  <Field
                    component={TextField}
                    name="areaOfExpertise"
                    label={
                      translated.pages.register.form.job.labels.areaOfExpertise
                    }
                    placeholder={
                      translated.pages.register.form.job.placheolders
                        .areaOfExpertise
                    }
                  />
                  <Field
                    component={TextField}
                    name="participateAs"
                    label={
                      translated.pages.register.form.job.labels.participateAs
                    }
                    placeholder={
                      translated.pages.register.form.job.placheolders
                        .participateAs
                    }
                    required
                  />
                  <Box
                    sx={(theme) => ({
                      gridRow: 4,
                      gridColumn: 'span 1',
                      [theme.breakpoints.up('md')]: {
                        gridRow: 3,
                      },
                    })}
                  >
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="participateAsTeam"
                      disabled={teamFieldsDisabled}
                      Label={{
                        label: `${translated.pages.register.form.job.labels.participateAsTeam}`,
                      }}
                    />
                  </Box>
                  {values.participateAsTeam && (
                    <Box
                      sx={(theme) => ({
                        gridRow: 5,
                        gridColumn: 'span 1',
                        [theme.breakpoints.up('md')]: {
                          gridRow: 4,
                        },
                      })}
                    >
                      <Box sx={{ marginTop: '-24px' }}>
                        <Field
                          component={TextField}
                          name="teamname"
                          label={
                            translated.pages.register.form.job.labels.teamname
                          }
                          placeholder={
                            translated.pages.register.form.job.placheolders
                              .teamname
                          }
                          disabled={teamFieldsDisabled}
                          required
                        />
                      </Box>
                      <Box sx={{ marginTop: '24px' }}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="mFundAcronymVisible"
                          disabled={teamFieldsDisabled}
                          Label={{
                            label: `${translated.pages.register.form.job.labels.mFundAcronymVisible}`,
                          }}
                        />
                      </Box>
                      {values.mFundAcronymVisible && (
                        <Box sx={{ marginTop: '8px' }}>
                          <Field
                            component={TextField}
                            name="mFundAcronym"
                            label={
                              translated.pages.register.form.job.labels
                                .mFundAcronym
                            }
                            placeholder={
                              translated.pages.register.form.job.placheolders
                                .mFundAcronym
                            }
                            disabled={teamFieldsDisabled}
                            required
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>

                {/* Other Information Form */}

                <Typography variant="h3" sx={{ mt: 16 }}>
                  {translated.pages.register.form.other.headline}
                </Typography>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    rowGap: theme.spacing(8),
                    columnGap: theme.spacing(6),
                  })}
                >
                  <Field
                    component={Select}
                    id="foodPreference"
                    name="foodPreference"
                    labelId="foodPreference-label"
                    label={
                      translated.pages.register.form.other.labels.foodPreference
                    }
                    placeholder={
                      translated.pages.register.form.other.placeholders
                        .foodPreference
                    }
                    required
                  >
                    {Object.entries(
                      translated.pages.register.form.other.fields.foodPreference
                    ).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      )
                    })}
                  </Field>

                  <Box sx={{ gridRow: 2, gridColumn: 'span 2' }}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="teambuilding"
                      Label={{
                        label: `${translated.pages.register.form.other.labels.teambuilding}`,
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      gridRow: 3,
                      gridColumn: '1 / -1',
                    }}
                  >
                    <Field
                      component={TextField}
                      name="annotations"
                      label={
                        translated.pages.register.form.other.labels.annotations
                      }
                      placeholder={
                        translated.pages.register.form.other.placeholders
                          .annotations
                      }
                      multiline
                      rows={9}
                      maxRows={9}
                      sx={{
                        width: '100%',
                      }}
                    />
                  </Box>
                </Box>
              </Container>

              {/* Disclaimer Section */}
              {/* Important Section - Yellow Background */}
              <Container
                component="section"
                maxWidth={false}
                sx={{ mt: 20, backgroundColor: 'rgba(255, 205, 1, 1)' }}
              >
                <Container
                  component="article"
                  maxWidth="lg"
                  id="disclaimer"
                  sx={{ py: 8 }}
                >
                  <Typography variant="h3">
                    {translated.pages.register.form.disclaimer.headline}
                  </Typography>
                  <Typography
                    variant="body2"
                    paragraph
                    fontWeight="700"
                    dangerouslySetInnerHTML={{
                      __html: translated.pages.register.form.disclaimer.copy1,
                    }}
                  />
                  <Typography
                    variant="body2"
                    paragraph
                    fontWeight="700"
                    dangerouslySetInnerHTML={{
                      __html: translated.pages.register.form.disclaimer.copy2,
                    }}
                  />
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    {Object.entries(
                      translated.pages.register.form.disclaimer.labels.box
                    ).map(([key, value]) => {
                      return (
                        <Field
                          key={key}
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name={key}
                          Label={{
                            label: ReactHtmlParser(value + ' *'),
                          }}
                          required
                        />
                      )
                    })}
                  </Box>
                </Container>
              </Container>

              <Container component="section" maxWidth="lg">
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    marginTop: '58px',
                  }}
                >
                  {Object.entries(
                    translated.pages.register.form.disclaimer.labels.beneath
                  ).map(([key, value]) => {
                    return (
                      <Field
                        key={key}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name={key}
                        Label={{
                          label: ReactHtmlParser(
                            value.label + (value.required ? ' *' : '')
                          ),
                        }}
                        required={value.required}
                        sx={
                          value.hideCheckbox && {
                            '& > *': {
                              display: 'none !important',
                            },
                          }
                        }
                      />
                    )
                  })}
                </Box>
              </Container>

              <Container
                component="section"
                maxWidth="lg"
                id="captcha"
                sx={(theme) => ({
                  marginTop: theme.spacing(20),
                  marginBottom: theme.spacing(2),
                })}
              >
                <ErrorMessage name="captcha" component="div" />
                <Captcha onCaptchaVerified={handleCaptchaVerified} />
              </Container>
              {/* Submit Button */}
              <Container
                component="section"
                maxWidth="lg"
                id="form2"
                sx={(theme) => ({
                  marginBottom: theme.spacing(17),
                })}
              >
                {formSubmitErrorMessage && (
                  <Typography
                    variant="body1"
                    sx={(theme) => ({
                      color: theme.palette.common.red[500],
                      mb: 2,
                      mt: 4,
                    })}
                  >
                    {formSubmitErrorMessage}
                  </Typography>
                )}
                <Button
                  variant="primary"
                  disabled={isSubmitting || !captchaSolution || formSubmitted}
                  onClick={submitForm}
                  isExternal={true}
                  type="submit"
                >
                  {translated.pages.register.form.submit.buttonText}
                </Button>
                <Typography variant="body1" sx={{ mt: 4 }}>
                  {translated.pages.register.form.submit.mandatory}
                </Typography>
              </Container>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}
