import React from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Grid, Box, Typography, Container, SvgIcon } from '@mui/material'
import { INavItemData } from '../navigation/drawerNav'
// import useGlobalText from '@system/hooks/useGlobalText'
import Link from '@components/core/link'

import BMDVLogo from '@static/img/logos/logo-bmdv.svg'
import FMFDATLogo from '@static/img/logos/logo-fmfdat.svg'

export type FooterProps = BASE.Helpers.IReactDefaultProps & {
  navigationItems?: Array<INavItemData>
}

export default function Footer({ navigationItems }: FooterProps) {
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  return (
    <Box
      component="footer"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.main,
        boxShadow: theme.boxShadows.teaser,
        mt: theme.spacing(60),
        position: 'relative',
        zIndex: 0,
      })}
    >
      {/* Bottom pattern */}
      <Box
        sx={(theme) => ({
          position: 'absolute',
          left: 0,
          top: '-220px',
          width: '360px',
          height: '220px',
          backgroundImage: `url(/img/logos/data-pattern-bottom.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top right',
          zIndex: '-1',
          [theme.breakpoints.down('lg')]: {
            left: '-20px',
            transform: 'scale(.75)',
            transformOrigin: 'bottom',
          },
        })}
      />

      {/* Begin footer elements */}
      <Box
        sx={(theme) => ({
          paddingTop: theme.spacing(15),
          paddingBottom: theme.spacing(9),
          [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          },
        })}
      >
        <Grid
          component={Container}
          container
          sx={(theme) => ({
            justifyContent: 'flex-start',
            flexFlow: 'row wrap',
            [theme.breakpoints.up('lg')]: {
              justifyContent: 'flex-end',
              flexFlow: 'row nowrap',
            },
          })}
        >
          {/* BWK Logo */}
          <Grid
            item
            xs="auto"
            component={Typography}
            variant="navigation"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              fontSize: theme.fontSize.get('base'),
              marginTop: theme.spacing(16),
              marginRight: 'auto',
              order: 2,
              [theme.breakpoints.up('lg')]: {
                marginTop: 0,
                order: 1,
              },
            })}
          >
            <a
              href={translated.global.footer.leftLogoLink}
              target="_blank"
              rel="noreferrer"
            >
              <SvgIcon
                sx={() => ({
                  width: 'auto',
                  height: '100%',
                  maxHeight: '62px',
                })}
                component={
                  lang === 'de'
                    ? (BMDVLogo as React.ElementType<unknown>)
                    : (FMFDATLogo as React.ElementType<unknown>)
                }
                viewBox="0 0 122 61"
              />
            </a>
          </Grid>

          {/* Navigation */}
          <Grid
            item
            xs="auto"
            container
            direction={{ xs: 'column', lg: 'row' }}
            sx={(theme) => ({
              width: '100% !important',
              alignItems: 'center',
              marginBottom: theme.spacing(20),
              order: 0,
              [theme.breakpoints.up('lg')]: {
                width: 'auto !important',
                marginRight: '144px',
                marginBottom: 0,
                order: 2,
              },
            })}
          >
            {navigationItems?.map((item) => {
              return (
                <Typography
                  key={item.id}
                  component={Link}
                  variant="footer"
                  isExternal={item.url ? true : false}
                  to={item.url ? item.url : item.fields.fullPath}
                  sx={(theme) => ({
                    '&:not(:last-of-type)': {
                      marginBottom: theme.spacing(6),
                      [theme.breakpoints.up('lg')]: {
                        marginBottom: 0,
                        marginRight: theme.spacing(10),
                      },
                    },
                  })}
                >
                  {item.titleNavigation}
                </Typography>
              )
            })}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            direction={{ xs: 'column', lg: 'row' }}
            sx={{
              order: 3,
              '& img': {
                height: '155px',
              },
            }}
          >
            <a
              href={translated.global.footer.rightLogoLink}
              target="_blank"
              rel="noreferrer"
            >
              <img src="/img/logos/BSH-Logo.webp" />
            </a>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
