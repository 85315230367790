import React, { ReactElement, useState, useEffect } from 'react'
import { useLanguageContext } from '@system/providers/LanguageProvider'
import { useTranslations } from '@system/hooks/useTranslations'

import { Box, CssBaseline } from '@mui/material'
import Header from '../header'
import Footer from '../footer'

export type LayoutProps = BASE.Helpers.IReactDefaultProps & {
  pageContext: BASE.Helpers.IPageContext
}

export default function Layout({ children }: LayoutProps): ReactElement {
  const [scrollDir, setScrollDirection] = useState('up')
  const { lang } = useLanguageContext()
  const translated = useTranslations(lang)

  // Set scroll direction
  useEffect(() => {
    let lastScrollTop = 0

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      if (scrollTop > lastScrollTop) {
        setScrollDirection('down')
      } else {
        setScrollDirection('up')
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'flex-start',
      }}
    >
      <CssBaseline />
      <Box
        sx={(theme) => ({
          position: 'absolute',
          right: 0,
          top: '133px',
          width: '450px',
          height: '220px',
          backgroundImage: `url(/img/logos/data-pattern-top.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '70px -180px',
          zIndex: 1,
          [theme.breakpoints.down('xl')]: {
            right: '-56px',
            top: '92px',
            transform: 'scale(.75)',
            transformOrigin: 'top',
          },
        })}
      />
      <Header
        navigationItems={translated.global.header.menu}
        scrollDir={scrollDir}
      />
      {children}
      <Footer navigationItems={translated.global.footer.menu} />
    </Box>
  )
}
