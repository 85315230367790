import React from 'react'

import { Box, Typography } from '@mui/material'

import Link from '@components/core/link'
import { INavItemData } from '@components/modules/global/navigation/drawerNav'

export interface INavItem {
  item: INavItemData
  closeNavWindow: () => void
}

export default function NavItemLevel3({ item, closeNavWindow }: INavItem) {
  return (
    <Box
      component="li"
      role="none"
      sx={(theme) => ({
        marginBottom: theme.spacing(2),
        width: 'fit-content',
      })}
    >
      <Typography
        component={Link}
        onClick={closeNavWindow}
        linkColor="black"
        variant="navigation"
        sx={(theme) => ({
          position: 'relative',
          display: 'block',
          //borderBottom: '2px dotted transparent',
          borderRadius: '18px',
          padding: theme.spacing(1.5, 4),
          fontWeight: 'normal',
          [theme.breakpoints.up('lg')]: {
            '&:hover': {
              backgroundColor: theme.palette.background.red,
              color: theme.palette.text.inverted,
            },
          },
        })}
        to={item.fields?.fullPath}
      >
        {item.titleNavigation}
      </Typography>
    </Box>
  )
}
