// Import main language files
import deMain from './de.json'
import enMain from './en.json'

// Import global translations
import headerDe from './global/header-de.json'
import headerEn from './global/header-en.json'
import footerDe from './global/footer-de.json'
import footerEn from './global/footer-en.json'

// Import page translations
import indexDe from './pages/index-de.json'
import indexEn from './pages/index-en.json'
import ablaufDe from './pages/ablauf-de.json'
import ablaufEn from './pages/ablauf-en.json'
import datenDe from './pages/daten-de.json'
import datenEn from './pages/daten-en.json'
import registerDe from './pages/register-de.json'
import registerEn from './pages/register-en.json'
import optinDe from './pages/optin-de.json'
import optinEn from './pages/optin-en.json'
import contactDe from './pages/contact-de.json'
import contactEn from './pages/contact-en.json'
import confirmDe from './pages/confirm-de.json'
import confirmEn from './pages/confirm-en.json'
import juryDe from './pages/jury-de.json'
import juryEn from './pages/jury-en.json'
import impressumDe from './pages/impressum-de.json'
import impressumEn from './pages/impressum-en.json'
import page404De from './pages/404-de.json'
import page404En from './pages/404-en.json'

// Combine translations
const de = {
  ...deMain,
  ...{
    global: {
      header: headerDe,
      footer: footerDe,
    },
    pages: {
      index: indexDe,
      ablauf: ablaufDe,
      daten: datenDe,
      register: registerDe,
      optin: optinDe,
      contact: contactDe,
      confirm: confirmDe,
      jury: juryDe,
      impressum: impressumDe,
      page404: page404De,
    },
  },
}

const en = {
  ...enMain,
  ...{
    global: {
      header: headerEn,
      footer: footerEn,
    },
    pages: {
      index: indexEn,
      ablauf: ablaufEn,
      daten: datenEn,
      register: registerEn,
      optin: optinEn,
      contact: contactEn,
      confirm: confirmEn,
      jury: juryEn,
      impressum: impressumEn,
      page404: page404En,
    },
  },
}

const messages: Record<string, Record<string, string>> = { de, en }
const defaultLang = 'de'
const availableLang = ['de', 'en']
export { messages, defaultLang, availableLang }
